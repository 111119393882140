import { ShopResponseDTO } from 'typings/dto/shop';
import Mapper from './Mapper';

export default class ShopMapper extends Mapper<Shop, ShopResponseDTO> {
  constructor() {
    super({
      name: 'name',
      dealerNumber: 'dealerNumber',
      shopNumber: 'shopNumber',
      technicalName: 'technicalName',
      timezone: 'timezone',
      tenant: 'tenant',
      address: 'address',
      department: 'department',
      code1c: 'code1c',
    });
  }

  public responseDTOToModel = (shop: ShopResponseDTO): Shop => {
    return {
      ...this.responseDTOToModelViaDiffMap(shop),
    };
  };

  // Этот маппер по умолчанию используется при запросе данных для фильтра, а там нужен descriptionsInDropdown
  public responseDTOToModelIdAndNamesOnly = (shop: ShopResponseDTO): Shop => {
    return this.responseDTOToModel(shop);
  };
}
